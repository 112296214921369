import { Link } from "gatsby"
import React from "react"
import Seo from "../components/Seo"
import seoContents from "../constants/seo-contents"

export default function error() {
  return (
    <div className="bottom-picture">
      <Seo
        title={`${seoContents.errorPage.title}`}
        description={`${seoContents.errorPage.description}`}
      />
      <div className="bottom-right-picture">
        <div className="error-container">
          <div className="logo">
            <h2>Page Not Found</h2>
            <Link to="/" className="btn btn-primary">
              homepage
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
