const seoContents = {
  errorPage: {
    title: "404",
    description: "404 - Not found",
  },
  homePage: {
    title: "Home Page",
    description: "Description",
  },
  privacyPolicy: {
    title: "Privacy Policy",
    description: "Privacy Policy",
  },
}
export default seoContents
